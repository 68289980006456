import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ActiveFilter, FilteringFeatureNames } from '@ciphr/shared/collections-filtering/models';

export const collectionsFilteringFeatureActionsFactory = (featureName: FilteringFeatureNames) =>
  createActionGroup({
    source: featureName,
    events: {
      'Clear Filters': emptyProps(),
      'Load Filters': props<{ params: unknown[] }>(),
      'Set Filters': props<{ activeFilters: ActiveFilter[] }>(),
      'Toggle Drawer': emptyProps(),
      'Reset State': emptyProps(),
    },
  });

export const collectionsFilteringFeatureActions = createActionGroup({
  source: 'Collections Filtering Feature',
  events: {
    'Close Drawer': emptyProps(),
    'Drawer Opened': emptyProps(),
  },
});
