import { catchError, combineLatest, filter, first, map, mergeMap, Observable, switchMap, throwError } from 'rxjs';
import { inject, InjectionToken } from '@angular/core';

import { ActionCreator, Store, Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MemoizedSelector } from '@ngrx/store/src/selector';

import { collectionsFilteringFeatureActionsFactory, selectActiveFilters } from '@ciphr/shared/collections-filtering/state';
import { FilteringFeatureNames } from '@ciphr/shared/collections-filtering/models';

import { CollectionData } from './collection-data.type';
import { CollectionItem } from './collection-item.type';
import { collectionsActionsFactory } from './collections-actions.factory';
import { CollectionsProjector } from './collections-projector.type';
import { collectionsSelectorsFactory } from './collections-selectors.factory';
import { CollectionState } from './collection-state.type';

type LoadEffectOptions<State> = {
  additionalFetchParamsSelectors?: MemoizedSelector<State, any>[];
  filteringFeatureName?: FilteringFeatureNames;
};

type LoadTriggerEffectOptions = {
  additionalActions?: ActionCreator[];
  filteringFeatureName?: FilteringFeatureNames;
};

export const collectionsEffectsFactory = <
  Collections extends Record<string, CollectionState>,
  CollectionName extends keyof Collections,
  DataAdapter,
>(
  collectionsActions: ReturnType<typeof collectionsActionsFactory>,
  collectionsProjector: CollectionsProjector<Record<string, unknown>, Collections>,
  dataAdapterToken: InjectionToken<DataAdapter>,
) => {
  const actions$ = inject(Actions);
  const dataAdapter = inject(dataAdapterToken);
  const store = inject(Store);

  const { selectCollectionListParams } = collectionsSelectorsFactory(collectionsProjector);

  const prepareCollectionLoadEffect = <
    FetchMethod extends (...params: any[]) => Observable<CollectionData<CollectionItem<Collections[CollectionName]>>>,
    State,
  >(
    collectionName: CollectionName,
    fetchMethod: FetchMethod,
    options?: LoadEffectOptions<State>,
  ): Observable<Action<typeof collectionsActions.collectionLoadedSuccessfully.type>> =>
    createEffect(() =>
      actions$.pipe(
        ofType(collectionsActions.loadCollection),
        filter((action) => action.collectionName === collectionName),
        mergeMap(() =>
          combineLatest([
            store.select(selectCollectionListParams(collectionName)),
            ...(options?.filteringFeatureName ? [store.select(selectActiveFilters(options.filteringFeatureName))] : []),
            ...(options?.additionalFetchParamsSelectors?.map((selector) => store.select(selector)) ?? []),
          ]).pipe(first()),
        ),
        switchMap(([listParams, filters, ...additionalParams]) =>
          fetchMethod.call(dataAdapter, listParams, filters, ...additionalParams).pipe(
            map((collectionData) =>
              collectionsActions.collectionLoadedSuccessfully({
                collectionData,
                collectionName,
              }),
            ),
            catchError((httpErrorResponse) => {
              store.dispatch(collectionsActions.collectionLoadingFailed({ collectionName }));
              return throwError(() => httpErrorResponse);
            }),
          ),
        ),
      ),
    );

  const prepareCollectionLoadTriggerEffect = (
    collectionName: CollectionName,
    options?: LoadTriggerEffectOptions,
  ): Observable<Action<typeof collectionsActions.loadCollection.type>> =>
    createEffect(() =>
      actions$.pipe(
        ofType(
          ...prepareFilteringActions(options?.filteringFeatureName),
          ...(options?.additionalActions ?? []),
          collectionsActions.changePaging,
          collectionsActions.search,
          collectionsActions.sort,
        ),
        filter((action) => !('collectionName' in action) || action.collectionName === collectionName),
        map(() => collectionsActions.loadCollection({ collectionName })),
      ),
    );

  const preparePagingResetEffect = (
    collectionName: CollectionName,
    filteringFeatureName?: FilteringFeatureNames,
  ): Observable<Action<typeof collectionsActions.resetPaging.type>> =>
    createEffect(() =>
      actions$.pipe(
        ofType(...prepareFilteringActions(filteringFeatureName), collectionsActions.search, collectionsActions.sort),
        filter((action) => !('collectionName' in action) || action.collectionName === collectionName),
        map(() => collectionsActions.resetPaging({ collectionName })),
      ),
    );

  const prepareSelectionResetEffect = (
    collectionName: CollectionName,
    triggerActions: ActionCreator[],
  ): Observable<Action<typeof collectionsActions.resetSelection.type>> =>
    createEffect(() =>
      actions$.pipe(
        ofType(...triggerActions),
        filter((action) => !('collectionName' in action) || action.collectionName === collectionName),
        map(() => collectionsActions.resetSelection({ collectionName })),
      ),
    );

  const prepareFilteringActions = (filteringFeatureName: FilteringFeatureNames | undefined): ActionCreator[] => {
    if (!filteringFeatureName) return [];

    return [
      collectionsFilteringFeatureActionsFactory(filteringFeatureName).clearFilters,
      collectionsFilteringFeatureActionsFactory(filteringFeatureName).setFilters,
    ];
  };

  return {
    prepareCollectionLoadEffect,
    prepareCollectionLoadTriggerEffect,
    preparePagingResetEffect,
    prepareSelectionResetEffect,
  };
};
