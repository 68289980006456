import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Selector } from '@ngrx/store/src/models';

import { FilteringFeatureNames } from '@ciphr/shared/collections-filtering/models';

import { CollectionsFilteringState } from './collections-filtering.reducer';

const selectFiltersState = (featureName: FilteringFeatureNames) => createFeatureSelector<CollectionsFilteringState>(featureName);

const createFilteringSelector = <Result>(featureName: FilteringFeatureNames, selector: Selector<CollectionsFilteringState, Result>) => {
  return createSelector(selectFiltersState(featureName), selector);
};

export const selectActiveFilters = (featureName: FilteringFeatureNames) => {
  return createFilteringSelector(featureName, (state) => state.activeFilters);
};

export const selectAppliedFiltersNumber = (featureName: FilteringFeatureNames) => {
  return createFilteringSelector(featureName, (state) => state.activeFilters.length);
};

export const selectAvailableFilters = (featureName: FilteringFeatureNames) => {
  return createFilteringSelector(featureName, (state) => state.availableFilters);
};

export const selectFiltersDrawerOpenedState = (featureName: FilteringFeatureNames) => {
  return createFilteringSelector(featureName, (state) => state.drawerOpened);
};
