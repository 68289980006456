import { ComponentPortal } from '@angular/cdk/portal';
import { inject, Injectable, Injector } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Overlay } from '@angular/cdk/overlay';

import { first, timer } from 'rxjs';

import { ChrSnackbarDirective } from '@ciphr-design-system/angular';

import { SNACK_BAR_DATA } from './snack-bar-data.token';
import { SnackBarComponent } from './snack-bar.component';

@Injectable({ providedIn: 'root' })
export class SnackBarService {
  private liveAnnouncer = inject(LiveAnnouncer);
  private overlay = inject(Overlay);

  open(message: string, palette: ChrSnackbarDirective['palette'], variant: ChrSnackbarDirective['variant'] = 'primary'): void {
    const snackBarInjector = Injector.create({ providers: [{ provide: SNACK_BAR_DATA, useValue: { message, palette, variant } }] });
    const snackBarPortal = new ComponentPortal(SnackBarComponent, null, snackBarInjector);

    const positionStrategy = this.overlay.position().global().centerHorizontally().bottom('3rem');
    const overlayRef = this.overlay.create({ positionStrategy, maxWidth: 'calc(100% - 1rem)' });

    overlayRef.attach(snackBarPortal);
    this.liveAnnouncer.announce(message);

    timer(4000)
      .pipe(first())
      .subscribe(() => overlayRef.dispose());
  }
}
