import { Component, inject } from '@angular/core';

import { ChrComponentsModule } from '@ciphr-design-system/angular';

import { SNACK_BAR_DATA } from './snack-bar-data.token';

@Component({
  standalone: true,
  imports: [ChrComponentsModule],
  template: `
    <chr-snackbar [palette]="snackbarData.palette" [variant]="snackbarData.variant">
      <span>{{ snackbarData.message }}</span>
    </chr-snackbar>
  `,
})
export class SnackBarComponent {
  readonly snackbarData = inject(SNACK_BAR_DATA);
}
